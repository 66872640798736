/* eslint-disable react/jsx-curly-newline */
/* eslint-disable nonblock-statement-body-position */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// eslint-disable-next-line import/no-named-as-default
import QuestionMark from '../../public/images/question-mark.svg';
import SubmitBox from './SubmitBox';
import DateBox from './DateBox';
import ToolTip from './ToolTip';

const MonthPicker = ({
  startText,
  endText,
  buttonText,
  submitLink,
  cssClass,
  startDescription,
  endDescription,
}) => {
  const { start, end } = useSelector((state) => state);
  const [viewToolTip, setViewToolTip] = React.useState(false);

  const toggleToolTip = () => {
    setViewToolTip(!viewToolTip);
  };
  const closeToolTip = () => {
    setViewToolTip(false);
  };

  return (
    <>
      <div className={`monthpicker-container ${cssClass}`}>
        <div className="month-picker">
          <img
            className="top-question-mark"
            tabIndex={0}
            onBlur={closeToolTip}
            onClick={toggleToolTip}
            src={QuestionMark}
            alt="QuestionMark"
          />
          <ToolTip
            show={viewToolTip}
            classCss="tool-tip-view"
            text={
              <>
                <strong>CHECK IN</strong>
                <br />
                {startDescription}
                <br />
                <br />
                <strong>CHECK OUT</strong>
                <br />
                {endDescription}
              </>
            }
          />
          <DateBox
            startText={startText}
            endText={endText}
            startDescription={startDescription}
            endDescription={endDescription}
          />
          <SubmitBox
            submitLink={`${submitLink.trim()}?&start=${start.timestamp}&end=${
              end.timestamp
            }`}
            buttonText={buttonText}
          />
        </div>
      </div>
    </>
  );
};

MonthPicker.defaultProps = {
  cssClass: "",
};

MonthPicker.propTypes = {
  startText: PropTypes.string.isRequired,
  endText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  submitLink: PropTypes.string.isRequired,
  helpButtonText: PropTypes.string.isRequired,
  helpHtmlTitle: PropTypes.string.isRequired,
  modalElementId: PropTypes.string.isRequired,
  modalLinkElementId: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
};

export default MonthPicker;
